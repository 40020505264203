/* Headings */
h1, h2, h3 {
  color: #064689;
  font-weight: bold;
}

/* Invoice Containers */
.compradorInfo, .facturaInfo {
  background-color: #fff;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Invoice Details */
.compradorInfo p, .facturaInfo p {
  margin: 8px 0;
  color: #5b6f84;
  font-size: 16px;
}

/* Invoice Products List */
.facturaInfo ul {
  list-style: none;
  padding: 0;
}

.facturaInfo li {
  background-color: #e2e4ef;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}

.facturaInfo li p {
  margin: 5px 0;
  color: #666;
  font-size: 14px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.upload-link {
  margin-left: auto;
  text-decoration: none;
  color: rgb(68, 68, 220);
  font-weight: none;
  font-size: 1rem;
}

.upload-link:hover {
  text-decoration: underline;
}

details {
  margin-top: 10px;
}

summary {
  cursor: pointer;
  font-weight: bold;
  color: #5b6f84;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

li {
  margin-bottom: 10px;
}

li p {
  margin: 0;
}