/* General styles */
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f1f3f5;
}

/* Header styles */
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.titleContainer h1 {
  font-size: 3rem;
  color: #064689;
  margin-bottom: 1rem;
}

.titleContainer p {
  font-size: 1.6rem;
  color: #5b6f84;
  text-align: center;
}

/* Button styles */
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
}

.startButton {
  background-color: #064689;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.startButton:hover {
  background-color: #083d77; /* Slightly darker shade on hover */
}