/* General Styles */

/* Login Container */
.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: #f1f3f5;
  padding: 10px;
}

/* Login Title */
.loginTitle {
  margin-bottom: 10px;
  text-align: center; /* Center the title */
}

.loginTitle h1 {
  font-size: 2.5rem;
  color: #064689;
}

/* Input Group */
.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.inputGroup label {
  font-size: 16px;
  color: #5b6f84;
  margin-bottom: 3px;
}

.inputField {
  height: 20px;
  width: 350px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.inputField:focus {
  border-color: #007bff;
  outline: none;
}

.errorLabel {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Login Button */
.loginButton {
  background-color:  #064689;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px; /* Add margin for spacing */
  width: 350px; /* Match input field width */
}

.loginButton:hover {
  background-color: #083d77; /* Slightly darker shade on hover */
}

/* Register Prompt */
.registerPrompt {
  display: flex;
  justify-content: space-between; /* Horizontally justify content */
  font-family: Arial, sans-serif; /* Specify a font family */
  font-size: 16px; /* Set the font size */
  line-height: 1.5; /* Adjust line spacing for better readability */
  color: #333; /* Set the text color */
  margin: 30px 0; /* Add margin for spacing */
}

.registerButton {
  background-color: #064689; /* Use a different color for contrast */
  color: white;
  padding: 10px 20px;
  font-size: 16px; /* Adjust font size as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 15px; /* Add margin for spacing */
  width: 150px; /* Match input field width */
}

.registerButton:hover {
  background-color: #083d77; /* Slightly darker shade on hover */
}